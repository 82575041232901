
/* rules to generally apply */
body{
  margin: 0;
  padding: 0;
  font-family:'arlon';
  overflow-x: hidden;
}

/* links */
a, a {
  --is-focused: false;
  color: #141414;
  font-weight: 700;
  text-decoration: none; 
  display: inline-block;
}

a::after {
  display: block;
  position: inherit;
  content: attr(title);
  font-weight: 800;
  border: 1px solid black;
  height: 0px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
  margin-top: -0.95ex;
}

/* focus (mouse hover) */
a:hover,
a:focus{
  color: #ff284d;
  /* text-shadow: 1px 0px 0px #ff284d; */
  /* letter-spacing: 1px; */
  font-weight: 800;
  text-decoration: none;
  --is-focused: true;
}

/* thin outline to show container elements */
.outline {
  border:1px solid white;
}

/* tool to centralise multiple images */
.container{
  text-align: center;
}

.heightCap{
    max-height: 60vh;
}

/* tool to centralise 1 image wide */
.centralise {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.img {
  display: inline-block;
}

.roundimg {
  display: inline-block;
  border-radius: 50%;
}

.bubbleimg {
  width: 100px;
  height: 100px;
}

.centerVideo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width:90%;
}

/* non-homepage navigation bar styling */
.navigation{
  position:relative;
  z-index: 1;
  display: flex;
}

/* header navigation spacing */
.margin {
  margin-left: 1.5%;
  margin-right: 1.5%;
}

/* header navigation colouring*/
.pink{
  background: pink;
}

.paleBlue{
  background: #b7dbd4;
}

.innerFlower{
  top:0px
}

/* home button */

.flower_box{
  display: flex;
  height: 120px;
  margin-left: 10px;
}


.flower{
  background-image: url(/assets/images/flower/flower_dark.png);
  position:relative;
  z-index: 2;
  height:100px;
  width:100px;
  background-position: center;
  background-size: contain;
  display: block;
  margin: auto;
}

.flower:hover, .flower:focus{
  background-image: url(/assets/images/flower/flower_pink.png);
  position:relative;
  z-index: 2;
  height:100px;
  width:100px;
  background-position: center;
  background-size: contain;
}

/* header navigation text*/
.homelinks {
  display: inline-block;
  text-align: center;
  width: 130px;
  height: 100px;
  line-height: 100px;
  font-size: 30px;
  font-weight: 700;
}

/* breather margins for image pages that need it */
.top_margin {
  margin-top: 5ex;
}

.bottom_margin {
  margin-bottom: 5ex;
}

.bottom_page_margin {
  margin-bottom: 200px;
}

.auto_lr_margin {
  margin-left: auto;
  margin-right: auto;
}

/* text */
.text{
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 30;
  text-decoration: none; 
}

.explainText{
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 20;
  margin: 20px;
  text-decoration: none; 
}

.justifyText{
  text-align: justify;
  text-align-last: center;
}

.text_spacer{
  line-height: 3ex;
}


.twodlink {
  display: flex;
  font-size: 3ex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: fit-content;
}

.pageblock{
  background: #FFC0CB;
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
}

.twodblock{
  top: 50%;
  transform: translate(0, -60%);
  position: relative;
}

.threedmodel {
  align-content: auto;
}

/* scaling tools for different devices */
/* phone */
@media only screen
  and (min-device-width: 320px) 
  and (max-device-width: 480px){
    .homelinks{
      width: 200px;
      font-size: 50px;
    }
    .navigation{
      display: flow-root;
    }
    .bigPlanet{
      width: 75%;
    }
    .smallHalf{
      width: 80%;
    }
    .half{
      width: 100%;
    }
}

/* vertical tablet */
@media only screen
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape){
    .navigation{
      display: flow-root;
    }
}

/* horizontal tablet */
@media only screen
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait){
}

/* too skinny for the nav bar */
@media screen
  and (max-width: 800) {
    .navigation{
      display: flow-root;
    }
}

/* desktop */
@media screen
  and (min-device-width: 1200px){
}

/* widescreen desktop */
@media screen
  and (min-device-width: 1600px){
    .portfolioSpacer {
      margin-top: 4.16666%;
      margin-bottom: 4.16666%;
      margin-left: auto;
      margin-right: auto;
      max-width: 66.666667%;
    }
}

/* fonts */

/* ultralight font */
@font-face{
  font-family: 'arlon';
  src:  url('/assets/fonts/arlon-light/arlon-light.woff') format('woff'),
        url('/assets/fonts/arlon-light/arlon-light.woff2') format('woff2'),
        url('/assets/fonts/arlon-light/arlon-light.ttf') format('truetype'), 
        url('/assets/fonts/arlon-light/arlon-light.otf') format('opentype'); 
  font-weight: 300;
}

/* light font */
@font-face{
  font-family: 'arlon';
  src:  url('/assets/fonts/arlon-light/arlon-light.woff') format('woff'),
        url('/assets/fonts/arlon-light/arlon-light.woff2') format('woff2'),
        url('/assets/fonts/arlon-light/arlon-light.ttf') format('truetype'), 
        url('/assets/fonts/arlon-light/arlon-light.otf') format('opentype'); 
  font-weight: 500;
}

/* semibold font */
@font-face {
	font-family: 'arlon';
	src:  url('/assets/fonts/arlon-semibold/arlon-semibold.woff') format('woff'),
        url('/assets/fonts/arlon-semibold/arlon-semibold.woff2') format('woff2'),
        url('/assets/fonts/arlon-semibold/arlon-semibold.ttf') format('truetype'),
        url('/assets/fonts/arlon-semibold/arlon-semibold.otf') format('opentype');
	font-weight: 700;
}

/* ultrabold font */
@font-face {
	font-family: 'arlon';
	src:  url('/assets/fonts/arlon-ultrabold/arlon-ultrabold.woff') format('woff'),
        url('/assets/fonts/arlon-ultrabold/arlon-ultrabold.woff2') format('woff2'),
        url('/assets/fonts/arlon-ultrabold/arlon-ultrabold.ttf') format('truetype'),
        url('/assets/fonts/arlon-ultrabold/arlon-ultrabold.otf') format('opentype');
	font-weight: 900;
}

