body {
  margin: 0;
  padding: 0;
  font-family: arlon;
  overflow-x: hidden;
}

a, a {
  --is-focused: false;
  color: #141414;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

a:after {
  position: inherit;
  content: attr(title);
  color: #0000;
  visibility: hidden;
  border: 1px solid #000;
  height: 0;
  margin-top: -.95ex;
  font-weight: 800;
  display: block;
  overflow: hidden;
}

a:hover, a:focus {
  color: #ff284d;
  --is-focused: true;
  font-weight: 800;
  text-decoration: none;
}

.outline {
  border: 1px solid #fff;
}

.container {
  text-align: center;
}

.heightCap {
  max-height: 60vh;
}

.centralise {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.img {
  display: inline-block;
}

.roundimg {
  border-radius: 50%;
  display: inline-block;
}

.bubbleimg {
  width: 100px;
  height: 100px;
}

.centerVideo {
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.navigation {
  z-index: 1;
  display: flex;
  position: relative;
}

.margin {
  margin-left: 1.5%;
  margin-right: 1.5%;
}

.pink {
  background: pink;
}

.paleBlue {
  background: #b7dbd4;
}

.innerFlower {
  top: 0;
}

.flower_box {
  height: 120px;
  margin-left: 10px;
  display: flex;
}

.flower {
  z-index: 2;
  background-image: url("flower_dark.d17db644.png");
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  position: relative;
}

.flower:hover, .flower:focus {
  z-index: 2;
  background-image: url("flower_pink.063c3bdc.png");
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 100px;
  position: relative;
}

.homelinks {
  text-align: center;
  width: 130px;
  height: 100px;
  font-size: 30px;
  font-weight: 700;
  line-height: 100px;
  display: inline-block;
}

.top_margin {
  margin-top: 5ex;
}

.bottom_margin {
  margin-bottom: 5ex;
}

.bottom_page_margin {
  margin-bottom: 200px;
}

.auto_lr_margin {
  margin-left: auto;
  margin-right: auto;
}

.text {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  text-decoration: none;
}

.explainText {
  color: #fff;
  text-align: center;
  margin: 20px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}

.justifyText {
  text-align: justify;
  text-align-last: center;
}

.text_spacer {
  line-height: 3ex;
}

.twodlink {
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  font-size: 3ex;
  display: flex;
}

.pageblock {
  background: pink;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
}

.twodblock {
  position: relative;
  top: 50%;
  transform: translate(0, -60%);
}

.threedmodel {
  align-content: auto;
}

@media only screen and (device-width >= 320px) and (device-width <= 480px) {
  .homelinks {
    width: 200px;
    font-size: 50px;
  }

  .navigation {
    display: flow-root;
  }

  .bigPlanet {
    width: 75%;
  }

  .smallHalf {
    width: 80%;
  }

  .half {
    width: 100%;
  }
}

@media only screen and (device-width >= 768px) and (device-width <= 1024px) and (orientation: landscape) {
  .navigation {
    display: flow-root;
  }
}

@media screen and (width <= 800px) {
  .navigation {
    display: flow-root;
  }
}

@media screen and (device-width >= 1600px) {
  .portfolioSpacer {
    max-width: 66.6667%;
    margin: 4.16666% auto;
  }
}

@font-face {
  font-family: arlon;
  src: url("arlon-light.c0a11cd2.woff") format("woff"), url("arlon-light.583d9d0d.woff2") format("woff2"), url("arlon-light.55186504.ttf") format("truetype"), url("arlon-light.60c31655.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: arlon;
  src: url("arlon-light.c0a11cd2.woff") format("woff"), url("arlon-light.583d9d0d.woff2") format("woff2"), url("arlon-light.55186504.ttf") format("truetype"), url("arlon-light.60c31655.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: arlon;
  src: url("arlon-semibold.9ddcce7b.woff") format("woff"), url("arlon-semibold.77a49f95.woff2") format("woff2"), url("arlon-semibold.c4d08ebf.ttf") format("truetype"), url("arlon-semibold.a5287e79.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: arlon;
  src: url("arlon-ultrabold.f942a4cf.woff") format("woff"), url("arlon-ultrabold.d83c8b91.woff2") format("woff2"), url("arlon-ultrabold.b5089c00.ttf") format("truetype"), url("arlon-ultrabold.5934c12f.otf") format("opentype");
  font-weight: 900;
}
/*# sourceMappingURL=index.36c93393.css.map */
